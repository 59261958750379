@import url('../src/styles/main-style.css');

.error_message {
  color: orangered !important;
  font-size: 15px !important;
}

.menu_left_button {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 7px !important;
  margin-right: 0rem !important;
}

.menu_right_button {
  margin-right: 0.5rem !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  padding: 7px !important;
}

.fc-dayGridMonth-button, .fc-button, .fc-button-primary, .fc-prev-button {
  /* border-bottom-right-radius: 0px; */
  /* border-top-right-radius: 0px; */
  background-color: #fff !important;
  color: #273444 !important;
  border: 1px solid #eff2f7 !important;
  text-transform: capitalize !important;
  padding: 0.5rem 1.25rem !important;
  font-size: .875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  font-weight: 600 !important;
}

.fc .fc-button-group {
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.tab_link {
  font-weight: 600 !important;
}

/* .td_input {
  width:70px !important;
} */

.prescription_table td, .prescription_table th{
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border-radius: 0.25rem !important;
  height: calc(1.5em + 1.5rem + 2px) !important;
  border: 1px solid #e0e6ed !important;
}

.td_select_2 > .select2-container--default .select2-selection--single {
  background-color: #fff;
  border-radius: 0.25rem !important;
  height: 42px !important; 	
  border: 1px solid #e0e6ed !important;
  /* width: 325px !important; */
  min-width: 250px !important
}

.td_select_2 > .select2-container--default .select2-selection--single .select2-selection__rendered {
    /* color: #444; */
    line-height: 20px !important;
	text-align: left !important;
}

.width100px {
  max-width: 100px !important;
}

.width150px {
  max-width: 150px !important;
}

.width200px {
  max-width: 200px !important;
}

.width60px {
  max-width: 60px !important;
}

.width250px {
  max-width: 250px !important;
}




.tab_content_form {
  margin-bottom: 6px !important;
}

.pdf_table {
  border: unset !important;
  
}

.pdf_th {
  border: unset !important;
  padding: 5px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.text_word_wrap {
  word-wrap: break-word !important;
  white-space: unset !important;
}

.table td, .table th {
  white-space: unset !important;
}

.rdt_Table div {
    white-space: unset !important;
    overflow: unset !important ;
    text-overflow: unset !important;
}

.card .table td, .card .table th {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pdf_footer_table {
  border: unset !important;
}

.pdf_footer_table td, .pdf_footer_table td {
  border: unset !important;
  border-top: unset !important;
  border-bottom: unset !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.table_dropdown_menu {
  position: unset !important;
}

.visibility_hidden{
  visibility: hidden !important;
}

.table_dropdown_menu.show {
    z-index: 999999;
    position: unset !important;
    inset: 0px 0px auto auto !important;
    margin: 0px !important;
    transform: translate(-7px, 0px) !important;
}

.bXLDzR {
  overflow-y: auto !important;
}

.fc-daygrid-event-harness {
  white-space: nowrap; 
  width: 90% !important; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.application-offset .container-application:before {
  height: 386px !important;
  border-bottom-left-radius: 0px !important;
}

.application .sidenav-user {
  height: auto !important;
}

.color_unset {
  color: unset !important;
}

.navbar .dropdown-menu {
  min-width: unset  !important;
}

.display_flex {
  display:inline-flex !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: unset !important;
  border-color: unset !important;
  
}

.dropdown.btn-primary:hover {
  /* color: white !important; */
  background-color: unset !important;
  border-color: #ff850000 !important;
  /* bor */
}


.treatment_options_div {
  max-height: 175px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  padding: 10px;
}
